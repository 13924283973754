import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import PageBreak from "../components/repeating_elements/page_break.js";
import { ArrowLink } from "../components/buttons/Arrow_Link.js";
import Overview from "../components/repeating_elements/Overview.js";
import ProjectItem from "../components/repeating_elements/Project_Item.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import BasicHero from "../components/Global/Basic_Hero.js";
import BackgroundImage from "../components/repeating_elements/Background-Image.js";
import EnterHeader from "../components/repeating_elements/Enter_Header.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


export default class Services_Single extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			featured_case_studies: this.props.pageContext.caseStudies
		}
	}
	componentDidMount() {

	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		if(data){

			data = this.state.data.acf;
			return (
				<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data.yoast_meta.yoast_wpseo_title}
					description={this.state.data.yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
						<div className={"page service-page " + page_ready}>
							<LazyLoad>
								<BasicHero className="center" fullHeight={true}>
									<EnterHeader className="styling-header-1 service-hero-text">{data.title}</EnterHeader>
									<BackgroundImage url={data.hero_desktop_image} mobileURL={data.hero_mobile_image} alt={data.title}></BackgroundImage>
								</BasicHero>
							</LazyLoad>
							{data.featured_case_study[0] && data.related_case_studies[1] ?
							<div>
								<LazyLoad>
									<div className="grid">
										<div className="grid-m-6 grid-t-12 grid-d-10 description-container">
											<p className="styling-eyebrow">{data.title} Description</p>
											<p className="overview-details">{data.description}</p>
										</div>
										<div className="grid-m-6 grid-t-12 grid-d-10 services-container">
											<h3 className="styling-eyebrow">Select services of interest:</h3>
											<ul>
												{(function(){
													var list = [];
													data.services_of_interests.forEach(function(i){
														list.push(<li key={i.service}>{i.service}</li>)
													})
													return list
												})()}
											</ul>
										</div>
									</div>
								</LazyLoad>
								<PageBreak />
								<LazyLoad>
									{
										(()=>{
											if(this.state.featured_case_studies){

												var fcs = this.state.featured_case_studies[0];
												return <Overview
													description={ fcs.acf.overview_description }
													image={fcs.acf.slides[0].desktop_image.url}
													services={fcs.acf.overview_services}
													awards={fcs.acf.overview_awards}
													link={fcs.slug}
													contact="true"
													/>;
											}
										})()
									}
								</LazyLoad>
								<PageBreak />
								<LazyLoad>
									{
										<div className="grid">
											<h4 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow">Relevant case studies</h4>
											<div className="row">
												{
													(()=>{
														if(this.state.featured_case_studies){
															var element = [];
															var lcs = this.state.featured_case_studies[1];
															var rcs = this.state.featured_case_studies[2];
															element.push(
																<div className="grid-m-6 grid-t-8 grid-d-8 related-case-study"><ProjectItem project={lcs} /></div>
															);
															element.push(
																<div className="grid-m-6 grid-t-8 grid-d-8 related-case-study"><ProjectItem project={rcs} /></div>
															);
															return element;
														}
													})()
												}
											</div>
										</div>
									}
								</LazyLoad>
							</div>
							: null}
							<PageBreak />
								<LazyLoad>
									<div className="grid">
										<div className="grid-m-6 grid-t-16 grid-d-16">
											<ArrowLink title="Explore more services" color="white" url="/services" />
										</div>
									</div>
								</LazyLoad>
							<PageBreak />
							<GetInTouchFooter />
							<Footer links={this.state.footerLinks} />
						</div>
					</div>
				</PageTransition></ParallaxProvider>
			);
		} else{

			return (
				<div></div>
			);
		}
	}
}
